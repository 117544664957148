<template>
    <div class="main-content position-relative max-height-vh-100 h-100">
        <div class="container-fluid py-4">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header pb-0">
                            <div class="d-flex align-items-center">
                                <p class="mb-0">Cobranças Realizadas:</p>

                                <!-- Botão de Atualizar Cobranças -->
                                <button @click="fetchTickets" class="btn btn-secondary btn-sm ms-auto">
                                    <i class="fa fa-refresh"></i> Atualizar Cobranças
                                </button>

                                <!-- Botão de Criar Cobrança Pix -->
                                <button @click="openPopup" class="btn btn-primary btn-sm ms-2">
                                    <i class="fa fa-plus"></i> Criar Cobrança Pix
                                </button>
                            </div>
                        </div>
                        <div style="min-height:60vh" class="card-body">
                            <div class="row">
                                <div class="col-12 table-responsive">
                                    <!-- Exibe o ícone de carregamento enquanto `loading` é true -->
                                    <div v-if="loading" class="text-center">
                                        <i class="fa fa-spinner fa-spin fa-3x"></i>
                                        <p>Carregando...</p>
                                    </div>
                                    <!-- Exibe a tabela quando `loading` é false -->
                                    <table v-else id="ticketsTable" class="table table-hover">
                                        <thead>
                                            <tr>
                                                <th scope="col"></th>
                                                <th scope="col">INFO</th>
                                                <th scope="col">DATA CADASTRO</th>
                                                <th scope="col">DATA PAGAMENTO</th>
                                                <th scope="col">DOC REF.</th>
                                            </tr>
                                        </thead>
                                        <tbody style="cursor:pointer">
                                            <tr v-for="(ticket) in tickets" :key="ticket.id" @click="goToClient(ticket.id)">
                                                <th scope="row">{{ ticket.id }}</th>
                                                <td>{{ ticket.titulo }}</td>
                                                <td>{{ ticket.created_at ? new Date(ticket.created_at).toLocaleDateString() : 'Não disponível' }}</td>
                                                <td>{{ ticket.updated_at ? new Date(ticket.updated_at).toLocaleDateString() : 'Não disponível' }}</td>
                                                <td>{{ ticket.produto.nome }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Componente do popup -->
        <CobrancaPixPopup :isVisible="isVisible" @close="closePopup" />
    </div>
</template>

<script>
import axios from '@/axios';
import $ from 'jquery';
import 'datatables.net';
import CobrancaPixPopup from '../../components/modals/CobrancaPixPopup.vue'; // Importando o componente do modal

export default {
    components: {
        CobrancaPixPopup, // Declarando o componente do modal
    },
    data() {
        return {
            tickets: [],  // Lista de tickets
            loading: true, // Carregamento
            dataTable: null, // Tabela com DataTables
            isVisible: false, // Variável para controlar a visibilidade do popup
        };
    },
    async mounted() {
        await this.fetchTickets(); // Busca os tickets
        this.$nextTick(() => {
            this.initializeDataTable();
        });
    },
    beforeUnmount() {
        if (this.dataTable) {
            this.dataTable.destroy();
        }
    },
    methods: {
        async fetchTickets() {
            try {
                this.loading = true;
                const response = await axios.get('/chamados');
                this.tickets = response.data;
            } catch (error) {
                console.error('Erro ao buscar chamados:', error);
            } finally {
                this.loading = false;
            }
        },
        initializeDataTable() {
            if (this.dataTable) {
                this.dataTable.destroy();
            }
            this.$nextTick(() => {
                this.dataTable = $('#ticketsTable').DataTable({
                    order: [[0, 'desc']],
                    language: {
                        search: "Pesquisar:",
                        lengthMenu: "Mostrar _MENU_ resultados por página",
                        info: "Mostrando _START_ a _END_ de _TOTAL_ chamados",
                        infoEmpty: "Mostrando 0 a 0 de 0 entradas",
                        infoFiltered: "(filtrado de _MAX_ entradas totais)",
                        loadingRecords: "Carregando...",
                        zeroRecords: "Nenhum registro encontrado",
                        emptyTable: "Nenhum dado disponível na tabela",
                        paginate: {
                            first: "Primeiro",
                            previous: "Anterior",
                            next: "Próximo",
                            last: "Último"
                        },
                        aria: {
                            sortAscending: ": ativar para ordenar a coluna em ordem crescente",
                            sortDescending: ": ativar para ordenar a coluna em ordem decrescente"
                        }
                    }
                });
            });
        },
        goToClient(clientId) {
            this.$router.push(`/EditarChamado/${clientId}`);
        },
        openPopup() {
            this.isVisible = true;
            document.body.style.overflow = 'hidden'; // Impede rolagem da página quando o modal está aberto
        },
        closePopup() {
            this.isVisible = false;
            document.body.style.overflow = 'auto'; // Restaura a rolagem
        },
    },
};
</script>

<style scoped>
/* Cores para os status */
.text-primary {
    color: #007bff !important;
}

.text-warning {
    color: #ffc107 !important;
}

.text-info {
    color: #17a2b8 !important;
}

.text-danger {
    color: #dc3545 !important;
}

.text-success {
    color: #28a745 !important;
}
</style>
