<template>
    <div class="container-fluid py-4">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header pb-0">
                        <div class="d-flex align-items-center">
                            <p class="mb-0">Cadastro de Cliente</p>
                        </div>
                    </div>
                    <div class="card-body">
                        <form @submit.prevent="handleSubmit">
                            <div class="row">
                                <div class="col-md-6">
                                    <label for="nome" class="form-label">Nome</label>
                                    <input id="nome" v-model="formData.nome_fantasia" class="form-control"
                                        placeholder="Nome" required />
                                </div>

                                <div class="col-md-6">
                                    <label for="razao_social" class="form-label">Razão Social</label>
                                    <input id="razao_social" v-model="formData.razao_social" class="form-control"
                                        placeholder="Razão Social" required />
                                </div>

                                <!-- Campo Tipo (Pessoa Física / Pessoa Jurídica) -->
                                <div class="col-md-6">
                                    <label for="tipo" class="form-label">Tipo</label>
                                    <select id="tipo" v-model="formData.tipo" class="form-control" required>
                                        <option value="">Selecione o tipo</option>
                                        <option value="fisica">Pessoa Física</option>
                                        <option value="juridica">Pessoa Jurídica</option>
                                    </select>
                                </div>

                                <!-- Exibir Documento se o tipo for "Pessoa Física" -->
                                <div class="col-md-6" v-if="formData.tipo === ''">
                                    <label for="identificacao" class="form-label">Documento</label>
                                    <input  v-model="formData.cpf" class="form-control"
                                        placeholder="Selecione o campo 'Tipo'" readonly />
                                </div>

                                <!-- Exibir CPF se o tipo for "Pessoa Física" -->
                                <div class="col-md-6" v-if="formData.tipo === 'fisica'">
                                    <label for="CPF" class="form-label">CPF</label>
                                    <input id="CPF" v-model="formData.cpf" class="form-control" placeholder="CPF"
                                        required />
                                </div>

                                <!-- Exibir CNPJ se o tipo for "Pessoa Jurídica" -->
                                <div class="col-md-6" v-if="formData.tipo === 'juridica'">
                                    <label for="CNPJ" class="form-label">CNPJ</label>
                                    <input id="CNPJ" v-model="formData.cnpj" class="form-control" placeholder="CNPJ"
                                        required />
                                </div>

                                <!-- Exibir NADA se o tipo for "Pessoa Física" -->
                                <div class="col-md-6" v-if="formData.tipo === ''">
                                    <label for="Identificacao" class="form-label">Documento</label>
                                    <input  v-model="formData.cpf" class="form-control"
                                        placeholder="Selecione o campo 'Tipo'" readonly />
                                </div>


                                <div class="col-md-6" v-if="formData.tipo === 'fisica'">
                                    <label for="RG" class="form-label">RG</label>
                                    <input id="RG" v-model="formData.rg" class="form-control" placeholder="RG"
                                        required />
                                </div>

                                <div class="col-md-6" v-if="formData.tipo === 'juridica'">
                                    <label for="IE" class="form-label">IE</label>
                                    <input id="IE" v-model="formData.rg" class="form-control" placeholder="IE"
                                        required />
                                </div>

                                <div class="col-md-6">
                                    <label for="endereco_email" class="form-label">Endereço Email Contato</label>
                                    <input id="endereco_email" v-model="formData.endereco" class="form-control"
                                        placeholder="Endereço Email Contato" required />
                                </div>

                                <div class="col-md-6">
                                    <label for="telefone" class="form-label">Telefone</label>
                                    <input id="telefone" v-model="formData.telefone" class="form-control"
                                        placeholder="Telefone" required />
                                </div>

                                <div class="col-md-6">
                                    <label for="celular" class="form-label">Celular</label>
                                    <input id="celular" v-model="formData.email" class="form-control" placeholder="Celular"
                                        required />
                                </div>

                                <div class="col-md-12">
                                    <label for="obs" class="form-label">Observações</label>
                                    <textarea id="obs" v-model="formData.obs" class="form-control"
                                        placeholder="Observações"></textarea>
                                </div>
                            </div>

                            <div class="row mb-3">
                                <div class="col-md-12">
                                    <hr />
                                    <button type="submit" class="btn btn-primary float-end btn-lg">
                                        <i class="fa fa-save"></i> Salvar Cadastro
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from '@/axios';
import Swal from 'sweetalert2';

export default {
    data() {
        return {
            formData: {
                razao_social: '',
                nome_fantasia: '',
                tipo: '',
                cpf: '',
                cnpj: '',
                rg: '',
                ie: '',
                endereco: '',
                obs: '',
                celular: '',
                telefone: ''
            }
        };
    },
    methods: {
        async handleSubmit() {
            try {
                if (this.formData.tipo === 'fisica' && !this.formData.cpf) {
                    Swal.fire('Erro', 'CPF é obrigatório para pessoa física.', 'error');
                    return;
                }
                if (this.formData.tipo === 'juridica' && !this.formData.cnpj) {
                    Swal.fire('Erro', 'CNPJ é obrigatório para pessoa jurídica.', 'error');
                    return;
                }

                await axios.post('/clientes', this.formData);
                Swal.fire('Sucesso', 'Cliente cadastrado com sucesso!', 'success');

                this.formData = {
                    razao_social: '',
                    nome_fantasia: '',
                    tipo: '',
                    cpf: '',
                    cnpj: '',
                    rg: '',
                    ie:'',
                    endereco: '',
                    obs: '',
                    celular: '',
                    telefone: ''
                };
            } catch (error) {
                Swal.fire('Erro', 'Erro ao cadastrar cliente.', 'error');
            }
        }
    }
};
</script>

<style scoped>
.main-content {
    padding-top: 4rem;
}
</style>