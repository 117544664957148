<template>
  <!-- Popup Overlay -->
  <div v-if="isVisible" class="popup-overlay">
    <div class="popup-content" @click.stop>
      <div class="d-flex justify-content-between mb-3">
        <h3>Criar Cobrança Pix</h3>
        <button @click="closeModal" class="btn-close" aria-label="Fechar"></button>
      </div>

      <form @submit.prevent="submitForm">
        <div class="row g-3">
          <div class="col-12">
            <label for="nomeDevedor" class="form-label">Nome do Devedor:</label>
            <input type="text" id="nomeDevedor" v-model="nomeDevedor" class="form-control" />
          </div>

          <div class="col-12">
            <label for="cpfCnpj" class="form-label">CPF/CNPJ:</label>
            <input 
              type="text" 
              id="cpfCnpj" 
              v-model="cpfCnpj" 
              @input="formatCpfCnpj"
              class="form-control" 
              maxlength="18"
            />
          </div>

          <div class="col-6">
            <label for="valor" class="form-label">Valor:</label>
            <input 
              type="text" 
              id="valor" 
              v-model="valor" 
              class="form-control" 
              required 
              placeholder="R$ 0,00" 
              @input="formatValue" 
            />
          </div>

          <div class="col-6">
            <label for="prazo" class="form-label">Prazo:</label>
            <select id="prazo" v-model="prazo" class="form-select">
              <option>15 minutos</option>
              <option>1 hora</option>
              <option>1 dia</option>
              <option>15 dias</option>
            </select>
          </div>

          <div class="col-6">
            <label for="documento" class="form-label">Documento:</label>
            <input 
              type="text" 
              id="documento" 
              v-model="documento" 
              @input="formatDocumento"
              class="form-control" 
            />
          </div>

          <div class="col-6">
            <label for="descricao" class="form-label">Descrição:</label>
            <textarea id="descricao" v-model="descricao" class="form-control" rows="1"></textarea>
          </div>
        </div>

        <div class="d-flex justify-content-end mt-3">
          <button @click="closeModal" type="button" class="btn btn-secondary me-2">Fechar</button>
          <button type="submit" class="btn btn-primary">Criar Cobrança</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isVisible: Boolean,
  },
  data() {
    return {
      nomeDevedor: '',
      cpfCnpj: '',
      valor: '',
      prazo: '1 hora',
      documento: '',
      descricao: '',
    };
  },
  methods: {
    submitForm() {
      const cobrancaData = {
        nomeDevedor: this.nomeDevedor,
        cpfCnpj: this.cpfCnpj,
        valor: this.valor,
        prazo: this.prazo,
        documento: this.documento,
        descricao: this.descricao,
      };
      this.$emit('submit', cobrancaData);
      this.closeModal();
    },
    closeModal() {
      this.$emit('close');
    },
    formatValue(event) {
      const value = event.target.value.replace(/\D/g, '');
      if (value.length > 0) {
        this.valor = (parseFloat(value) / 100).toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        });
      } else {
        this.valor = '';
      }
    },
    formatCpfCnpj(event) {
      let value = event.target.value.replace(/\D/g, '');
      if (value.length > 14) {
        value = value.slice(0, 14);
      }
      if (value.length <= 11) {
        value = value.replace(/(\d{3})(\d)/, '$1.$2');
        value = value.replace(/(\d{3})(\d)/, '$1.$2');
        value = value.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
      } else {
        value = value.replace(/^(\d{2})(\d)/, '$1.$2');
        value = value.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');
        value = value.replace(/\.(\d{3})(\d)/, '.$1/$2');
        value = value.replace(/(\d{4})(\d)/, '$1-$2');
      }
      this.cpfCnpj = value;
    },
    formatDocumento(event) {
      this.documento = event.target.value.replace(/\D/g, '');
    },
  },
};
</script>

<style scoped>
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background: white;
  padding: 30px;
  border-radius: 12px;
  max-width: 450px;
  width: 100%;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

textarea {
  resize: vertical; 
  min-height: 38px;
}
</style>