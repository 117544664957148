<template>
    <div class="main-content position-relative max-height-vh-100 h-100">
        <div class="container-fluid py-4">
            <div class="row" bis_skin_checked="1">
                <div class="col-lg-3 col-md-6 col-12" bis_skin_checked="1">
                    <div class="mb-4 card" bis_skin_checked="1">
                        <div class="p-3 card-body" bis_skin_checked="1">
                            <div class="d-flex flex-row-reverse justify-content-between" bis_skin_checked="1">
                                <div bis_skin_checked="1">
                                    <div class="text-center icon icon-shape bg-gradient-primary border-radius-2xl"
                                        bis_skin_checked="1">
                                        <i class="text-lg opacity-10 fa fa-dollar-sign" aria-hidden="true"></i>
                                    </div>
                                </div>
                                <div class="" bis_skin_checked="1">
                                    <div class="numbers" bis_skin_checked="1">
                                        <p class="mb-0 text-sm text-uppercase font-weight-bold">Cobranças Totais</p>
                                        <h5 class="font-weight-bolder">0</h5>
                                        <span class="text-sm text-success">Total de cobranças realizadas</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-12" bis_skin_checked="1">
                    <div class="mb-4 card" bis_skin_checked="1">
                        <div class="p-3 card-body" bis_skin_checked="1">
                            <div class="d-flex flex-row-reverse justify-content-between" bis_skin_checked="1">
                                <div bis_skin_checked="1">
                                    <div class="text-center icon icon-shape bg-gradient-danger border-radius-2xl"
                                        bis_skin_checked="1">
                                        <i class="text-lg opacity-10 fa fa-calendar-day" aria-hidden="true"></i>
                                    </div>
                                </div>
                                <div class="" bis_skin_checked="1">
                                    <div class="numbers" bis_skin_checked="1">
                                        <p class="mb-0 text-sm text-uppercase font-weight-bold">Cobranças Hoje</p>
                                        <h5 class="font-weight-bolder">0</h5>
                                        <span class="text-sm text-success">Hoje 26/07</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-12" bis_skin_checked="1">
                    <div class="mb-4 card" bis_skin_checked="1">
                        <div class="p-3 card-body" bis_skin_checked="1">
                            <div class="d-flex flex-row-reverse justify-content-between" bis_skin_checked="1">
                                <div bis_skin_checked="1">
                                    <div class="text-center icon icon-shape bg-gradient-success border-radius-2xl"
                                        bis_skin_checked="1">
                                        <i class="text-lg opacity-10 fa fa-hourglass-half" aria-hidden="true"></i>
                                    </div>
                                </div>
                                <div class="" bis_skin_checked="1">
                                    <div class="numbers" bis_skin_checked="1">
                                        <p class="mb-0 text-sm text-uppercase font-weight-bold">Cobranças Pendentes</p>
                                        <h5 class="font-weight-bolder">0</h5>
                                        <span class="text-sm text-danger">Pendentes de pagamento</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-12" bis_skin_checked="1">
                    <div class="mb-4 card" bis_skin_checked="1">
                        <div class="p-3 card-body" bis_skin_checked="1">
                            <div class="d-flex flex-row-reverse justify-content-between" bis_skin_checked="1">
                                <div bis_skin_checked="1">
                                    <div class="text-center icon icon-shape bg-gradient-warning border-radius-2xl"
                                        bis_skin_checked="1">
                                        <i class="text-lg opacity-10 fa fa-calendar-check" aria-hidden="true"></i>
                                    </div>
                                </div>
                                <div class="" bis_skin_checked="1">
                                    <div class="numbers" bis_skin_checked="1">
                                        <p class="mb-0 text-sm text-uppercase font-weight-bold">Cobranças Agendadas</p>
                                        <h5 class="font-weight-bolder">0</h5>
                                        <span class="text-sm text-info">Pagamentos agendados</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header pb-0">
                            <div class="d-flex align-items-center">
                                <p class="mb-0">App PixSystem</p>
                                <router-link to="/CobrancaCadastrar" class="btn btn-primary btn-sm ms-auto"><i
                                        class="fa fa-plus"></i> Criar Cobrança</router-link>
                            </div>
                        </div>
                        <div style="min-height:60vh" class="card-body text-center">
                            <div class="row">
                                <div class="col-12">
                                    <img style="max-width: 100%;"
                                        src="https://site.pixsystem.com.br/wp-content/uploads/2024/07/Design-sem-nome-3-800x800.png">
                                </div>
                                <div class="col-12 text-center mt-4">
                                    <h4>App PixSystem</h4>
                                </div>
                            </div>
                            Em desenvolvimento
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>