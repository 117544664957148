<template>
  <div class="container py-4">
    <div class="row">
      <div class="col-md-12">
        <div class="card shadow-sm">
          <div class="card-body p-4">
            <div class="text-center mb-4">
              <h2 class="h3 mb-2">Tubokraft</h2>
              <p class="mb-1">Av. Comendador Camilo Júlio, 1600 - Parque Industrial, Sorocaba - SP, 18086-000</p>
              <p>CNPJ: 54.319.470/0001-41</p>
            </div>
            <hr class="mb-4">
            <h1 class="card-title mb-3">Suas Cobranças</h1>

            <div class="table-responsive mt-3">
              <table class="table table-striped table-hover">
                <thead class="table-dark">
                  <tr>
                    <th>Selecionar</th>
                    <th>Descrição</th>
                    <th>Valor</th>
                    <th>Vencimento</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="cobranca in cobrancas" :key="cobranca.id">
                    <td>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" :id="'cobranca-' + cobranca.id" v-model="cobranca.selecionada">
                      </div>
                    </td>
                    <td>{{ cobranca.descricao }}</td>
                    <td>{{ formatarMoeda(cobranca.valor) }}</td>
                    <td>{{ formatarData(cobranca.vencimento) }}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="d-flex justify-content-between align-items-center mt-4">
              <p class="h4">Total selecionado: {{ formatarMoeda(totalSelecionado) }}</p>
              <button class="btn btn-primary" @click="gerarQRCode" :disabled="totalSelecionado === 0">
                Gerar QR Code PIX
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal QR Code -->
  <div class="modal fade" id="qrCodeModal" tabindex="-1" aria-labelledby="qrCodeModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="qrCodeModalLabel">QR Code PIX</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Fechar"></button>
        </div>
        <div class="modal-body text-center">
          <img :src="qrCodeUrl" alt="QR Code PIX" class="img-fluid mb-3" v-if="qrCodeUrl">
          <p>Valor total: {{ formatarMoeda(totalSelecionado) }}</p>
          <p class="text-muted">Escaneie o QR Code acima com o app do seu banco para pagar via PIX</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { Modal } from 'bootstrap'

// Simulação de dados de cobrança
const cobrancas = ref([
  { id: 1, descricao: 'Fatura de Energia', valor: 150.00, vencimento: '2023-05-10', selecionada: false },
  { id: 2, descricao: 'Fatura de Água', valor: 80.00, vencimento: '2023-05-15', selecionada: false },
  { id: 3, descricao: 'Internet', valor: 99.90, vencimento: '2023-05-20', selecionada: false },
  { id: 4, descricao: 'Aluguel', valor: 1200.00, vencimento: '2023-05-05', selecionada: false },
])

const totalSelecionado = computed(() => {
  return cobrancas.value
    .filter(cobranca => cobranca.selecionada)
    .reduce((total, cobranca) => total + cobranca.valor, 0)
})

const qrCodeUrl = ref('')

const formatarMoeda = (valor) => {
  return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(valor)
}

const formatarData = (data) => {
  return new Date(data).toLocaleDateString('pt-BR')
}

const gerarQRCode = () => {
  // Aqui você deve implementar a lógica para gerar o QR Code PIX
  // Esta é uma simulação usando uma API de geração de QR Code
  const valorTotal = totalSelecionado.value.toFixed(2)
  qrCodeUrl.value = `https://api.qrserver.com/v1/create-qr-code/?size=200x200&data=PIX:${valorTotal}`
  
  // Abre o modal
  const qrCodeModal = new Modal(document.getElementById('qrCodeModal'))
  qrCodeModal.show()
}
</script>