<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header pb-0">
            <div class="d-flex align-items-center">
              <p class="mb-0">Relatórios PIX:</p>
            </div>
          </div>
          <div style="min-height:60vh" class="card-body">
            <!-- Filtros de Pesquisa -->
            <div class="row mb-4">
              <div class="col-md-3">
                <label for="nomeDescricao">Nome/Descrição:</label>
                <input type="text" id="nomeDescricao" v-model="filters.nomeDescricao" class="form-control"
                  placeholder="Nome ou Descrição" />
              </div>
              <div class="col-md-3">
                <label for="docRef">Doc Ref (separe por vírgula):</label>
                <input type="text" id="docRef" v-model="filters.docRef" class="form-control"
                  placeholder="Código de referência" />
              </div>
              <div class="col-md-3">
                <label for="situacao">Situação:</label>
                <select id="situacao" v-model="filters.situacao" class="form-select">
                  <option value="">Todos</option>
                  <option value="pago">Pago</option>
                  <option value="aguardando">Aguardando Pagamento</option>
                  <option value="expirado">Expirado</option>
                </select>
              </div>
              <div class="col-md-3">
                <label for="usuario">Usuário:</label>
                <input type="text" id="usuario" v-model="filters.usuario" class="form-control" placeholder="Usuário" />
              </div>
            </div>
            <div class="row mb-4">
              <div class="col-md-3">
                <label for="dataCadastroDe">Data Cadastro (Início):</label>
                <input type="date" id="dataCadastroDe" v-model="filters.dataCadastroDe" class="form-control" />
              </div>
              <div class="col-md-3">
                <label for="dataCadastroAte">Data Cadastro (Fim):</label>
                <input type="date" id="dataCadastroAte" v-model="filters.dataCadastroAte" class="form-control" />
              </div>
              <div class="col-md-3">
                <label for="dataPagamentoDe">Data Pagamento (Início):</label>
                <input type="date" id="dataPagamentoDe" v-model="filters.dataPagamentoDe" class="form-control" />
              </div>
              <div class="col-md-3">
                <label for="dataPagamentoAte">Data Pagamento (Fim):</label>
                <input type="date" id="dataPagamentoAte" v-model="filters.dataPagamentoAte" class="form-control" />
              </div>
            </div>

            <!-- Botões de Filtrar e Imprimir -->
            <div class="d-flex justify-content-between mb-4">
              <button @click="imprimirCobrancas" class="btn btn-secondary me-2">
                <i class="fa fa-print"></i> Imprimir
              </button>

              <button @click="filtrarCobranças" class="btn btn-primary me-2">
                <i class="fa fa-filter"></i> Filtrar
              </button>
            </div>

            <!-- Exibição de carregamento ou tabela de cobranças -->
            <div v-if="loading" class="text-center">
              <i class="fa fa-spinner fa-spin fa-3x"></i>
              <p>Carregando...</p>
            </div>
            <div v-else-if="filteredCobrancas.length" class="table-responsive">
              <table id="cobrancasTable" class="table table-hover">
                <thead>
                  <tr>
                    <th scope="col">Id</th>
                    <th scope="col">Doc Ref.</th>
                    <th scope="col">Nome</th>
                    <th scope="col">Usuário</th>
                    <th scope="col">Valor</th>
                    <th scope="col">Situação</th>
                    <th scope="col">Data</th>
                    <th scope="col">Data Pg</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(cobranca, index) in filteredCobrancas" :key="cobranca.id"
                    @click="goToEditCobranca(cobranca.id)" style="cursor: pointer;">
                    <th scope="row">{{ index + 1 }}</th>
                    <td>
                      <i :class="getStatusIcon(cobranca.status)" :style="getStatusStyle(cobranca.status)"></i>
                      {{ cobranca.status }}
                    </td>
                    <td>{{ formatCurrency(cobranca.valor_original) }}</td>
                    <td>{{ cobranca.nome_devedor }}</td>
                    <td>{{ cobranca.descricao }}</td>
                    <td>{{ formatDate(cobranca.created_at) }}</td>
                    <td>{{ cobranca.data_pagamento ? formatDate(cobranca.data_pagamento) : '---' }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-else class="text-center">
              <p>Nenhuma cobrança encontrada</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/axios";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";

export default {
  data() {
    return {
      cobrancas: [],
      filters: {
        nomeDescricao: "",
        docRef: "",
        situacao: "",
        usuario: "",
        dataCadastroDe: "",
        dataCadastroAte: "",
        dataPagamentoDe: "",
        dataPagamentoAte: "",
      },
      loading: true,
    };
  },
  computed: {
    filteredCobrancas() {
      return this.cobrancas.filter((cobranca) => {
        // Filtro por nome/descrição
        if (this.filters.nomeDescricao && !cobranca.descricao.toLowerCase().includes(this.filters.nomeDescricao.toLowerCase())) {
          return false;
        }
        // Filtro por doc ref
        if (this.filters.docRef) {
          const docRefs = this.filters.docRef.split(',').map(ref => ref.trim());
          if (!docRefs.includes(cobranca.doc_ref)) {
            return false;
          }
        }

        // Filtro por situação
        if (this.filters.situacao && cobranca.status !== this.filters.situacao) {
          return false;
        }

        // Filtro por usuário
        if (this.filters.usuario && !cobranca.nome_devedor.toLowerCase().includes(this.filters.usuario.toLowerCase())) {
          return false;
        }
        // Filtro por data de cadastro
        if (this.filters.dataCadastroDe && new Date(cobranca.created_at) < new Date(this.filters.dataCadastroDe)) {
          return false;
        }
        if (this.filters.dataCadastroAte && new Date(cobranca.created_at) > new Date(this.filters.dataCadastroAte)) {
          return false;
        }

        // Filtro por data de pagamento
        if (this.filters.dataPagamentoDe && cobranca.data_pagamento && new Date(cobranca.data_pagamento) < new Date(this.filters.dataPagamentoDe)) {
          return false;
        }
        if (this.filters.dataPagamentoAte && cobranca.data_pagamento && new Date(cobranca.data_pagamento) > new Date(this.filters.dataPagamentoAte)) {
          return false;
        }

        return true;
      });
    },
  },
  async mounted() {
    await this.fetchCobrancas();
  },
  methods: {
    async fetchCobrancas() {
      try {
        const response = await axios.get("/cobrancas_usuario");
        this.cobrancas = response.data;
      } catch (error) {
        console.error("Erro ao buscar cobranças:", error);
      } finally {
        this.loading = false;
      }
    },
    goToEditCobranca(cobrancaId) {
      this.$router.push(`/EditarCobranca/${cobrancaId}`);
    },
    formatCurrency(value) {
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(value);
    },
    getStatusIcon(status) {
      switch (status) {
        case "ATIVA":
          return "fa fa-hourglass-start text-warning";
        case "CONCLUIDA":
          return "fa fa-check-circle text-success";
        case "CANCELADO":
          return "fa fa-times-circle text-danger";
        case "EXPIRADO":
          return "fa fa-hourglass-end text-secondary";
        default:
          return "";
      }
    },
    getStatusStyle(status) {
      switch (status) {
        case "ATIVA":
          return { color: "orange" };
        case "CONCLUIDA":
          return { color: "green" };
        case "CANCELADO":
          return { color: "red" };
        case "EXPIRADO":
          return { color: "gray" };
        default:
          return {};
      }
    },
    formatDate(date) {
      return format(new Date(date), "dd/MM/yyyy HH:mm:ss", { locale: ptBR });
    },
    filtrarCobranças() {
    },
    imprimirCobrancas() {
      // Implemente a lógica para imprimir aqui
    },
  },
};
</script>

<style scoped>
/* Seus estilos personalizados */
</style>
