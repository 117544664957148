<template>
    <!-- Navbar -->
    <nav class="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl" id="navbarBlur"
        data-scroll="false">
        <div class="container-fluid py-1 px-3">
            <nav aria-label="breadcrumb">
                <h6 class="font-weight-bolder text-white mb-0">APP PIXSYSTEM</h6>
            </nav>
            <div @click="toggleSidenav" class="collapse navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-4 sidenav-toggler-line" id="navbar">
                <ul class="navbar-nav justify-content-end">
                    <li class="nav-item d-xl-none ps-3 d-flex align-items-center fz-3">
                        <a href="javascript:;" class="nav-link text-white p-0 sidenav-toggler-line">
                            <div class="sidenav-toggler-inner sidenav-toggler-line">
                                <i class="fa fa-bars fs-1 sidenav-toggler-line"></i>
                            </div>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
    <!-- End Navbar -->
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue';

export default {
    name: 'HeaderPage',
    setup() {
        const isSidenavOpen = ref(false);

        const toggleSidenav = () => {
            isSidenavOpen.value = !isSidenavOpen.value;
            const body = document.getElementsByTagName('body')[0];
            const sidenav = document.getElementById('sidenav-main');
            const className = 'g-sidenav-pinned';

            if (isSidenavOpen.value) {
                body.classList.add(className);
                sidenav.classList.add('bg-white');
                sidenav.classList.remove('bg-transparent');
            } else {
                body.classList.remove(className);
                setTimeout(() => {
                    sidenav.classList.remove('bg-white');
                }, 100);
                sidenav.classList.add('bg-transparent');
            }
        };

        const closeSidenav = (e) => {
            if (isSidenavOpen.value && !e.target.classList.contains('sidenav-toggler-line')) {
                isSidenavOpen.value = false;
                const body = document.getElementsByTagName('body')[0];
                const sidenav = document.getElementById('sidenav-main');
                body.classList.remove('g-sidenav-pinned');
                setTimeout(() => {
                    sidenav.classList.remove('bg-white');
                }, 100);
                sidenav.classList.add('bg-transparent');
            }
        };

        onMounted(() => {
            document.addEventListener('click', closeSidenav);
        });

        onUnmounted(() => {
            document.removeEventListener('click', closeSidenav);
        });

        return {
            toggleSidenav
        };
    }
};
</script>

<style scoped>
/* Add your custom styles here */
</style>