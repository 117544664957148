<template>
    <div class="container-fluid py-4">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header pb-0">
                        <div class="d-flex align-items-center">
                            <p class="mb-0">Cobranças Realizadas:</p>
                            <!-- Botão de Atualizar -->
                            <button @click="fetchCobrancas" class="btn btn-secondary btn-sm ms-auto">
                                <i class="fa fa-refresh"></i> Atualizar
                            </button>
                        </div>
                    </div>
                    <div style="min-height: 60vh" class="card-body">
                        <div class="table-responsive">
                            <!-- Exibe o ícone de carregamento enquanto `loading` é true -->
                            <div v-if="loading" class="text-center">
                                <i class="fa fa-spinner fa-spin fa-3x"></i>
                                <p>Carregando...</p>
                            </div>
                            <!-- Exibe a tabela quando `loading` é false -->
                            <table v-else id="cobrancasTable" class="table table-hover">
                                <thead>
                                    <tr>
                                        <th scope="col">CLIENTE</th>
                                        <th scope="col">COMANDA</th>
                                        <th scope="col">VALOR</th>
                                        <th scope="col">DATA</th>
                                        <th scope="col">PAGO EM</th>
                                        <th scope="col">DESCRIÇÃO</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <!-- Exibe os dados quando o carregamento termina -->
                                    <tr v-for="(cobranca, index) in cobrancas" :key="cobranca.id"
                                        @click="goToEditCobranca(cobranca.id)" style="cursor: pointer;">
                                        <th scope="row">{{ index + 1 }}</th>
                                        <td>{{ cobranca.nome_devedor }}</td>
                                        <td>{{ formatCurrency(cobranca.valor) }}</td>
                                        <td>{{ formatDate(cobranca.created_at) }}</td>
                                        <td>{{ formatDate(cobranca.pago_em) }}</td>
                                        <td>{{ cobranca.descricao }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from '@/axios';
import $ from 'jquery';
import 'datatables.net';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

export default {
    data() {
        return {
            cobrancas: [],
            loading: true,
            dataTable: null,
        };
    },
    async mounted() {
        await this.fetchCobrancas();
        this.$nextTick(() => {
            this.initializeDataTable();
        });
    },

    beforeUnmount() {
        if (this.dataTable) {
            this.dataTable.destroy();
        }
    },
    methods: {
        async fetchCobrancas() {
            try {
                this.loading = true;
                const response = await axios.get('/cobrancas_usuario');
                this.cobrancas = response.data;
            } catch (error) {
                console.error('Erro ao buscar cobranças:', error);
            } finally {
                this.loading = false;
            }
        },
        refreshCobrancas() {
            this.fetchCobrancas(); // Recarrega os dados
        },
        initializeDataTable() {
            if (this.dataTable) {
                this.dataTable.destroy();
            }
            this.$nextTick(() => {
                this.dataTable = $('#cobrancasTable').DataTable({
                    order: [[0, 'desc']],
                    language: {
                        search: "Pesquisar:",
                        lengthMenu: "Mostrar _MENU_ Chamados",
                        info: "Mostrando _START_ a _END_ de _TOTAL_ chamados",
                        infoEmpty: "Mostrando 0 a 0 de 0 entradas",
                        infoFiltered: "(filtrado de _MAX_ entradas totais)",
                        loadingRecords: "Carregando...",
                        zeroRecords: "Nenhum registro encontrado",
                        emptyTable: "Nenhum dado disponível na tabela",
                        paginate: {
                            first: "Primeiro",
                            previous: "Anterior",
                            next: "Próximo",
                            last: "Último"
                        },
                        aria: {
                            sortAscending: ": ativar para ordenar a coluna em ordem crescente",
                            sortDescending: ": ativar para ordenar a coluna em ordem decrescente"
                        }
                    }
                });
            });
        },
        goToEditCobranca(cobrancaId) {
            this.$router.push(`/EditarCobranca/${cobrancaId}`);
        },
        formatCurrency(value) {
            return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);
        },
        formatDate(date) {
            return format(new Date(date), 'dd/MM/yyyy HH:mm:ss', { locale: ptBR });
        },
    }
};
</script>

<style scoped>
.main-content {
    padding-top: 4rem;
}
</style>