import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../components/HomePage.vue';
import LoginPage from '../components/LoginPage.vue';
import ProdutosPage from '../pages/vendas/ProdutosPage.vue';
import NotFoundPage from '../pages/NotFoundPage.vue';
import CategoriasPage from '../pages/vendas/CategoriasPage.vue';
import ClientesPage from '../pages/clientes/ClientesPage.vue';
import CadastroCliente from '../pages/clientes/CadastroCliente.vue';
import UsuariosPage from '../pages/clientes/UsuariosPage.vue';
import ListaVendas from '../pages/vendas/ListaVendas.vue';
import ListaCobrancas from '../pages/cobrancas/ListaCobrancas.vue';
import ChamadosPage from '../pages/cobrancas/ChamadosPage.vue';
import RelatorioTransacoes from '../pages/cobrancas/RelatorioTransacoes.vue';
import CobrancasPage from '../pages/cobrancas/CobrancasPage.vue';
import CobrancaCadastrar from '../pages/cobrancas/CobrancaCadastrar.vue';
import AssinaturasPage from '../pages/vendas/AssinaturasPage.vue';
import AssinaturaCadastrar from '../pages/vendas/AssinaturaCadastrar.vue';
import CobrancasCliente from '@/pages/cobrancas/CobrancasCliente.vue';

const routes = [
    {
        path: '/home',
        name: 'Home',
        component: HomePage,
        meta: { requiresAuth: true } // Indica que a rota precisa de autenticação
    },
    {
        path: '/usuarios',
        name: 'Usuarios',
        component: UsuariosPage,
        meta: { requiresAuth: true } // Indica que a rota precisa de autenticação
    },
    {
        path: '/',
        name: 'Home',
        component: HomePage,
        meta: { requiresAuth: true } // Indica que a rota precisa de autenticação
    },
    {
        path: '/Produtos',
        name: 'ProdutosPage',
        component: ProdutosPage,
        meta: { requiresAuth: true } // Indica que a rota precisa de autenticação
    },
    {
        path: '/categorias',
        name: 'Categorias',
        component: CategoriasPage,
    },
    {
        path: '/ClientesPage',
        name: 'Clientes',
        component: ClientesPage,
    },
    {
        path: '/CadastroCliente',
        name: 'CadastroCliente',
        component: CadastroCliente,
    },
    {
        path: '/CobrancasCliente',
        name: 'CobrancasCliente',
        component: CobrancasCliente,
    },
    {
        path: '/login',
        name: 'Login',
        component: LoginPage
    },
    {
        path: '/404',
        name: '404',
        component: NotFoundPage
    },
    {
        path: '/vendas',
        name: 'Vendas',
        component: ListaVendas
    },
    {
        path: '/ChamadoPage',
        name: 'ChamadoPage',
        component: ChamadosPage
    },
    {
        path: '/ListaCobrancas',
        name: 'ListaCobrancas',
        component: ListaCobrancas,
    },
    {
        path: '/RelatorioTransacoes',
        name: 'RelatorioTransacoes',
        component: RelatorioTransacoes
    },
    {
        path: '/ListaVendas',
        name: 'ListaVendas',
        component: ListaVendas
    },
    {
        path: '/CobrancasPage',
        name: 'CobrancasPage',
        component: CobrancasPage
    },
    {
        path: '/CobrancaCadastrar',
        name: 'CobrancaCadastrar',
        component: CobrancaCadastrar
    },
    {
        path: '/AssinaturasPage',
        name: 'AssinaturasPage',
        component: AssinaturasPage
    },
    {
        path: '/AssinaturaCadastrar',
        name: 'AssinaturaCadastrar',
        component: AssinaturaCadastrar
    },
    {
        path: '/:pathMatch(.*)*', // Captura todas as rotas não definidas
        redirect: { name: '404' } // Redireciona para a página de login
    },
    
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

router.beforeEach((to, from, next) => {
    const isAuthenticated = !!localStorage.getItem('jwt'); // Verifica se há um token armazenado

    if (to.meta.requiresAuth && !isAuthenticated) {
        //next({ name: 'Login' }); // Redireciona para a página de login se não autenticado
        next(); 
    } else {
        next(); // Permite a navegação se autenticado ou se a rota não requer autenticação
    }
});

export default router;