// src/axios.js
import axios from 'axios';
import router from './router'; // Certifique-se de importar o roteador

const instance = axios.create({
  //baseURL: 'https://api-pix.cbminformatica.com.br/api',
  baseURL: 'http://127.0.0.1:8000/api',
});

// Adicionar o token de autorização a partir do localStorage
instance.interceptors.request.use(config => {
  const token = localStorage.getItem('jwt');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, error => {
  return Promise.reject(error);
});

// Interceptor de resposta para lidar com status 401
instance.interceptors.response.use(response => {
  return response;
}, error => {
  if (error.response && error.response.status === 401) {
    localStorage.removeItem('jwt'); // Remove o token inválido
    router.push({ name: 'Login' }); // Redireciona para a página de login
  }
  return Promise.reject(error);
});

export default instance;
