<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header pb-0">
            <div class="d-flex align-items-center">
              <p class="mb-0">Lista de Cobranças</p>
              <router-link to="/NovaCobranca" class="btn btn-primary btn-sm ms-auto">
                <i class="fa fa-plus"></i> Nova Cobrança
              </router-link>
            </div>
          </div>
          <div style="min-height:60vh" class="card-body">
            <div class="table-responsive">
              <table id="cobrancasTable" class="table table-hover">
                <thead>
                  <tr>
                    <th scope="col">Código</th>
                    <th scope="col">Status</th>
                    <th scope="col">Valor</th>
                    <th scope="col">Nome do Devedor</th>
                    <th scope="col">Nome do Funcionário</th>
                    <th scope="col">Descrição</th>
                    <th scope="col">Data</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="loading">
                    <td colspan="7" class="text-center">
                      <i class="fa fa-spinner fa-spin"></i> Carregando...
                    </td>
                  </tr>
                  <tr v-else-if="!cobrancas.length">
                    <td colspan="7" class="text-center">
                      Nenhuma cobrança encontrada.
                    </td>
                  </tr>
                  <tr v-for="(cobranca, index) in cobrancas" :key="cobranca.id" @click="goToEditCobranca(cobranca.id)"
                    style="cursor: pointer;">
                    <th scope="row">{{ index + 1 }}</th>
                    <td>
                      <i :class="getStatusIcon(cobranca.status)" :style="getStatusStyle(cobranca.status)"></i>
                      {{ cobranca.status }}
                    </td>
                    <td>{{ formatCurrency(cobranca.valor_original) }}</td>
                    <td>{{ cobranca.nome_devedor }}</td>
                    <td>{{ cobranca.nome_funcionario || 'N/A' }}</td>
                    <td>{{ cobranca.descricao }}</td>
                    <td>{{ formatDate(cobranca.created_at) }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */

import axios from "@/axios";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";

export default {
  data() {
    return {
      cobrancas: [],
      loading: true, // Manter o estado de loading
    };
  },
  async mounted() {
    // Simular carregamento ou fazer uma chamada real à API
    setTimeout(() => {
      this.loading = false;
    }, 2000); // Simular carregamento por 2 segundos
  },
  methods: {
    goToEditCobranca(cobrancaId) {
      this.$router.push(`/EditarCobranca/${cobrancaId}`);
    },
    formatCurrency(value) {
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(value);
    },
    getStatusIcon(status) {
      switch (status) {
        case "ATIVA":
          return "fa fa-hourglass-start text-warning";
        case "CONCLUIDA":
          return "fa fa-check-circle text-success";
        case "CANCELADO":
          return "fa fa-times-circle text-danger";
        case "EXPIRADO":
          return "fa fa-hourglass-end text-secondary";
        default:
          return "";
      }
    },
    getStatusStyle(status) {
      switch (status) {
        case "ATIVA":
          return { color: "orange" };
        case "CONCLUIDA":
          return { color: "green" };
        case "CANCELADO":
          return { color: "red" };
        case "EXPIRADO":
          return { color: "gray" };
        default:
          return {};
      }
    },
    formatDate(date) {
      return format(new Date(date), "dd/MM/yyyy HH:mm:ss", { locale: ptBR });
    },
  },
};
</script>

<style scoped>
.table-responsive {
  max-height: 60vh;
  overflow-y: auto;
}
</style>
