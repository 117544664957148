<template>
  <div id="app">
    <SideBar v-if="showSidebar" />
    <div class="main-content position-relative max-height-vh-100 h-100">
      <HeaderPage v-if="showSidebar"/>
      <router-view />
    </div>
    <FooterTemplate v-if="showSidebar" />
  </div>
</template>

<script>
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import HeaderPage from './components/HeaderPage.vue';
import FooterTemplate from './components/FooterTemplate.vue';
import SideBar from './components/SideBar.vue';

export default {
  name: 'App',
  components: {
    HeaderPage,
    FooterTemplate,
    SideBar
  },
  setup() {
    const route = useRoute();

    const showSidebar = computed(() => {
      return route.path !== '/login' && route.path !== '/404' && route.path !=='/CobrancasCliente';
    });

    return {
      showSidebar
    };
  }
}
</script>

<style>
/* Estilos globais para App.vue */
</style>
