<template>
    <div class="container-fluid py-4">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header pb-0">
                        <div class="d-flex align-items-center">
                            <p class="mb-0">Assinaturas:</p>
                            <router-link to="/AssinaturaCadastrar" class="btn btn-primary btn-sm ms-auto">
                                <i class="fa fa-plus"></i> Assinatura Cobrança
                            </router-link>
                        </div>
                    </div>
                    <div style="min-height:60vh" class="card-body">
                        <div class="table-responsive">
                            <table id="assinaturasTable" class="table table-hover">
                                <thead>
                                    <tr>
                                        <th scope="col">ID</th>
                                        <th scope="col">Nome</th>
                                        <th scope="col">Cliente</th>
                                        <th scope="col">Vencimento</th>
                                        <th scope="col">Valor</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-if="loading">
                                        <td colspan="5" class="text-center">
                                            <i class="fa fa-spinner fa-spin"></i> Carregando...
                                        </td>
                                    </tr>
                                    <tr v-else-if="!assinaturas.length">
                                        <td colspan="5" class="text-center">
                                            Nenhuma assinatura encontrada.
                                        </td>
                                    </tr>
                                    <tr v-for="(assinatura, index) in assinaturas" :key="assinatura.id" @click="goToEditAssinatura(assinatura.id)"
                                        style="cursor: pointer;">
                                        <th scope="row">{{ index + 1 }}</th>
                                        <td>{{ assinatura.nome_devedor }}</td>
                                        <td>{{ assinatura.nome_cliente || 'N/A' }}</td>
                                        <td>{{ formatDate(assinatura.vencimento) }}</td>
                                        <td>{{ formatCurrency(assinatura.valor_original) }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import axios from "@/axios";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
export default {
    data() {
        return {
            assinaturas: [],
            loading: true, // Manter o estado de loading
        };
    },
    async mounted() {
        this.loading = false; // Mantenha o estado de loading em false
    },
    methods: {
        goToEditAssinatura(assinaturaId) {
            this.$router.push(`/EditarAssinatura/${assinaturaId}`);
        },
        formatCurrency(value) {
            return new Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL",
            }).format(value);
        },
        formatDate(date) {
            return format(new Date(date), "dd/MM/yyyy", { locale: ptBR });
        },
    },
};
</script>
