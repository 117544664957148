<template>
    <div class="container-fluid py-4">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header pb-0">
                        <div class="d-flex align-items-center">
                            <p class="mb-0">Cadastro de Assinatura</p>
                        </div>
                    </div>
                    <div class="card-body">
                        <form @submit.prevent="handleSubmit">
                            <div class="row">
                                <!-- Seletor de Cliente -->
                                <div class="col-md-6">
                                    <label for="cliente" class="form-label">Cliente</label>
                                    <select id="cliente" v-model="formData.cliente" class="form-control" required>
                                        <option value="">Selecione um cliente</option>
                                        <option v-for="cliente in clientes" :key="cliente.id" :value="cliente.id">
                                            {{ cliente.nome }}
                                        </option>
                                    </select>
                                </div>

                                <!-- Descrição -->
                                <div class="col-md-6">
                                    <label for="descricao" class="form-label">Descrição</label>
                                    <input id="descricao" v-model="formData.descricao" class="form-control"
                                        placeholder="Descrição" required />
                                </div>

                                <!-- Data de Vencimento -->
                                <div class="col-md-6">
                                    <label for="data_vencimento" class="form-label">Data de Vencimento</label>
                                    <input id="data_vencimento" type="date" v-model="formData.data_vencimento" class="form-control"
                                        required />
                                </div>

                                <!-- Endereço de Email para envio da fatura -->
                                <div class="col-md-6">
                                    <label for="email_fatura" class="form-label">Email para Envio da Fatura</label>
                                    <input id="email_fatura" v-model="formData.email_fatura" class="form-control"
                                        placeholder="Email para Envio da Fatura" required />
                                </div>

                                <!-- Observações -->
                                <div class="col-md-12">
                                    <label for="observacoes" class="form-label">Observações</label>
                                    <textarea id="observacoes" v-model="formData.observacoes" class="form-control"
                                        placeholder="Observações não serão exibidas para o cliente."></textarea>
                                </div>
                            </div>

                            <div class="row mb-3">
                                <div class="col-md-12">
                                    <hr />
                                    <button type="submit" class="btn btn-primary float-end btn-lg">
                                        <i class="fa fa-save"></i> Criar Assinatura
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from '@/axios';
import Swal from 'sweetalert2';
export default {
    data() {
        return {
            formData: {
                cliente: '',
                descricao: '',
                data_vencimento: '',
                email_fatura: '',
                observacoes: '',
            },
            clientes: [] 
        };
    },
    created() {
        this.fetchClientes();
    },
    methods: {
        async fetchClientes() {
            try {
                const response = await axios.get('/clientes'); 
                this.clientes = response.data; 
            } catch (error) {
                console.error('Erro ao buscar clientes:', error);
                // Removido o alerta de erro
                // Swal.fire('Erro', 'Não foi possível carregar a lista de clientes.', 'error');
            }
        },
        async handleSubmit() {
            try {
                await axios.post('/assinaturas', this.formData); 
                Swal.fire('Sucesso', 'Assinatura cadastrada com sucesso!', 'success');
                this.formData = {
                    cliente: '',
                    descricao: '',
                    data_vencimento: '',
                    email_fatura: '',
                    observacoes: '',
                };
            } catch (error) {
                Swal.fire('Erro', 'Erro ao cadastrar assinatura.', 'error');
            }
        }
    }
};
</script>

<style scoped>
.main-content {
    padding-top: 4rem;
}
</style>
