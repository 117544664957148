<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header pb-0">
            <div class="d-flex align-items-center">
              <p class="mb-0">Cobranças</p>
              <router-link to="/CobrancaCadastrar" class="btn btn-primary btn-sm ms-auto">
                <i class="fa fa-plus"></i> Criar Cobrança
              </router-link>
            </div>
          </div>
          <div style="min-height:60vh" class="card-body">
            <div class="table-responsive">
              <table id="cobrancasTable" class="table table-hover">
                <thead>
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Nome</th>
                    <th scope="col">Cliente</th>
                    <th scope="col">Vencimento</th>
                    <th scope="col">Valor</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="loading">
                    <td colspan="5" class="text-center">
                      <i class="fa fa-spinner fa-spin"></i> Carregando...
                    </td>
                  </tr>
                  <tr v-else-if="!cobrancas.length">
                    <td colspan="5" class="text-center">
                      Nenhuma cobrança encontrada.
                    </td>
                  </tr>
                  <tr v-for="(cobranca, index) in cobrancas" :key="cobranca.id" @click="goToEditCobranca(cobranca.id)"
                    style="cursor: pointer;">
                    <th scope="row">{{ index + 1 }}</th>
                    <td>{{ cobranca.nome_devedor }}</td>
                    <td>{{ cobranca.nome_cliente || 'N/A' }}</td>
                    <td>{{ formatDate(cobranca.vencimento) }}</td>
                    <td>{{ formatCurrency(cobranca.valor_original) }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

import axios from "@/axios";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";

export default {
  data() {
    return {
      cobrancas: [],
      loading: true, // Manter o estado de loading
    };
  },
  async mounted() {
    this.loading = false; // Mantenha o estado de loading em false
  },
  methods: {
    goToEditCobranca(cobrancaId) {
      this.$router.push(`/EditarCobranca/${cobrancaId}`);
    },
    formatCurrency(value) {
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(value);
    },
    formatDate(date) {
      return format(new Date(date), "dd/MM/yyyy", { locale: ptBR });
    },
  },
};
</script>
